
import { Component, Vue } from 'vue-property-decorator'
import { TableData } from '@/types/devicesCategory'

@Component({
  name: 'CateGoryManage'
})
export default class extends Vue {
  private searchData = {
    deviceTypeName: ''
  }

  private page = 1
  private size = 10
  private total = 0
  private loading = false
  private tableData: TableData[] = []

  created () {
    this.getTableList()
  }

  onSearch () {
    this.page = 1
    this.getTableList()
  }

  getTableList () {
    this.loading = true
    this.$axios.get<{total: number; list: TableData[]}>(this.$apis.deviceCategory.selectDeviceTypeByPage, {
      page: this.page,
      size: this.size,
      ...this.searchData
    }).then(res => {
      console.log('res: ', res)
      if (res) {
        this.total = res.total || 0
        this.tableData = res.list || []
      }
    }).finally(() => {
      this.loading = false
    })
  }

  goDetail (id: string) {
    console.log('row: ', id)
    this.$router.push({ name: 'deviceCategoryDetail', params: { id } })
  }

  goEdit (id: string) {
    console.log('row: ', id)
    this.$router.push({ name: 'deviceCategoryEdit', params: { id } })
  }
}
